'use client';

import Link from 'next/link';
import { Trans, useTranslation } from 'react-i18next';
import ErrorPage from 'widgets/error-page';
import { Box } from '@mui/material';
import Form from 'shared/ui/form';

export default function Page() {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        mx: 'auto',
        maxWidth: '400px',
        minHeight: '100dvh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Box
        sx={{
          flex: 1,
          px: 2,
          pt: 2,
        }}
      >
        <ErrorPage image={'/assets/error-404.png'}>
          <Trans
            i18nKey={'error404Page.description'}
            components={[<Link href={'/'} key={'link'} />]}
          />
        </ErrorPage>
      </Box>
      <Box>
        <Form.ProgressSubmit
          onClick={() => {
            location.href = '/';
          }}
          progress={0}
        >
          {t('returnHome')}
        </Form.ProgressSubmit>
      </Box>
    </Box>
  );
}
